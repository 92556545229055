<template>
  <div class="layout" :class="$route.meta.class || 'h-screen '">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout'
};
</script>
